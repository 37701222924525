<template lang="pug">
v-form(
  v-model="form"
  @submit.prevent
)
      
  //- Age
  .mb-4
    .text-subtitle-1.font-weight-normal.primary--text.d-flex(
      @click="locked.freg_age ? null : expanded.freg_age = !expanded.freg_age"
    )
      v-icon(color="primary" small left) mdi-human-cane
      | Alder
      v-spacer
      v-btn(icon small)
        v-icon(color="secondary" small)
          | {{ expanded.freg_age ? 'mdi-minus' : 'mdi-plus' }}
    v-divider.mt-2(:class="{ 'pb-4': expanded.freg_age }")

    template(v-if="expanded.freg_age")
      v-range-slider(
        v-model="freg_age"
        :disabled="locked.freg_age"
        :min="freg_age_min"
        :max="freg_age_max"
        height="48"
        color="secondary"
        track-color="#0000001f"
        hide-details
      )
        template(#prepend)
          v-text-field.mr-4(
            :disabled="locked.freg_age"
            :value="freg_age_at_min ? '∞' : freg_age[0]"
            :type="freg_age_at_min ? 'text' : 'number'"
            :readonly="freg_age_at_min"
            :min="freg_age_min"
            :max="freg_age_max"
            @change="$set(freg_age, 0, $event)"
            style="width:110px"
            rounded filled dense
            hide-details
          )
        template(#append)
          v-text-field.ml-4(
            :disabled="locked.freg_age"
            :value="freg_age_at_max ? '∞' : freg_age[1]"
            :type="freg_age_at_max ? 'text' : 'number'"
            :readonly="freg_age_at_max"
            :min="freg_age_min"
            :max="freg_age_max"
            @change="$set(freg_age, 1, $event)"
            style="width:110px"
            rounded filled dense
            hide-details
          )

  //- Gender
  .mb-4
    .text-subtitle-1.font-weight-normal.primary--text.d-flex(
      @click="locked.freg_gender ? null : expanded.freg_gender = !expanded.freg_gender"
    )
      v-icon(color="primary" small left) mdi-gender-male-female
      | Kjønn
      v-spacer
      v-btn(icon small)
        v-icon(color="secondary" small)
          | {{ expanded.freg_gender ? 'mdi-minus' : 'mdi-plus' }}
    v-divider.mt-2(:class="{ 'pb-4': expanded.freg_gender }")

    template(v-if="expanded.freg_gender")
      v-checkbox.float-left.mt-0.mr-8(
        v-model="freg_gender"
        :disabled="locked.freg_gender"
        label="Mann"
        value="M"
        color="secondary"
        hide-details
      )
      v-checkbox.mt-0(
        v-model="freg_gender"
        :disabled="locked.freg_gender"
        label="Kvinne"
        value="F"
        color="secondary"
        hide-details
      )

  //- Postal Code
  .mb-4
    .text-subtitle-1.font-weight-normal.primary--text.d-flex(
      @click="locked.nrop_postalCode ? null : expanded.nrop_postalCode = !expanded.nrop_postalCode"
    )
      v-icon(color="primary" small left) mdi-mailbox
      | Postnummer
      v-spacer
      v-btn(icon small)
        v-icon(color="secondary" small)
          | {{ expanded.nrop_postalCode ? 'mdi-minus' : 'mdi-plus' }}
    v-divider.mt-2(:class="{ 'pb-4': expanded.nrop_postalCode }")

    template(v-if="expanded.nrop_postalCode")
      v-range-slider(
        v-model="nrop_postalCode"
        :disabled="locked.nrop_postalCode"
        :min="nrop_postalCode_min"
        :max="nrop_postalCode_max"
        height="48"
        color="secondary"
        track-color="#0000001f"
        hide-details
      )
        template(#prepend)
          v-text-field.mr-4(
            :disabled="locked.nrop_postalCode"
            :value="nrop_postalCode_at_min ? '∞' : nrop_postalCode[0].toString().padStart(4, '0')"
            :min="nrop_postalCode_min"
            :max="nrop_postalCode_max"
            @change="changePostalCode(0, $event)"
            type="text"
            style="width:110px"
            rounded filled dense
            hide-details
          )
        template(#append)
          v-text-field.ml-4(
            :disabled="locked.nrop_postalCode"
            :value="nrop_postalCode_at_max ? '∞' : nrop_postalCode[1].toString().padStart(4, '0')"
            :readonly="nrop_postalCode_at_max"
            :min="nrop_postalCode_min"
            :max="nrop_postalCode_max"
            @change="changePostalCode(1, $event)"
            type="text"
            style="width: 110px"
            rounded filled dense
            hide-details
          )

  //- City
  .mb-4
    .text-subtitle-1.font-weight-normal.primary--text.d-flex(
      @click="locked.nrop_city ? null : expanded.nrop_city = !expanded.nrop_city"
    )
      v-icon(color="primary" small left) mdi-sign-direction
      | By
      v-spacer
      v-btn(icon small)
        v-icon(color="secondary" small)
          | {{ expanded.nrop_city ? 'mdi-minus' : 'mdi-plus' }}
    v-divider.mt-2(:class="{ 'pb-4': expanded.nrop_city }")

    template(v-if="expanded.nrop_city")
      v-combobox.filter-chip(
        v-model="nrop_city"
        :disabled="locked.nrop_city || loading_search.nrop_city"
        :loading="loading_search.nrop_city"
        :search-input.sync="input_search.nrop_city"
        :items="items_search.nrop_city"
        :menu-props="menuProps()"
        multiple chips
        rounded filled dense
        hide-details
      )
        template(#no-data)
          v-list-item(v-if="input_search.nrop_city")
            v-list-item-content
              v-list-item-title
                | Vi finner ikke "<strong>{{ input_search.nrop_city }}</strong>" hos oss.
                | Trykk <kbd>enter</kbd> for å legge til uansett.
        template(#selection="{ attrs, item, parent, selected }")
          v-chip(
            v-bind="attrs"
            :input-value="selected"
            color="secondary"
            small
          )
            span.pr-2 {{ item }}
            v-icon(
              @click="parent.selectItem(item)"
              small
            ) mdi-close

  //- Housing type
  .mb-4
    .text-subtitle-1.font-weight-normal.primary--text.d-flex(
      @click="locked.freg_housingType ? null : expanded.freg_housingType = !expanded.freg_housingType"
    )
      v-icon(color="primary" small left) mdi-home-city
      | Bruksenhet
      v-spacer
      v-btn(icon small)
        v-icon(color="secondary" small)
          | {{ expanded.freg_housingType ? 'mdi-minus' : 'mdi-plus' }}
    v-divider.mt-2(:class="{ 'pb-4': expanded.freg_housingType }")

    template(v-if="expanded.freg_housingType")
      v-autocomplete.filter-chip(
        v-model="freg_housingType"
        :disabled="locked.freg_housingType"
        :search-input.sync="input.freg_housingType"
        :items="items.freg_housingType"
        :menu-props="menuProps()"
        multiple chips
        rounded filled dense
        hide-details
      )
        template(#no-data)
          v-list-item(v-if="input.freg_housingType")
            v-list-item-content
              v-list-item-title
                | Vi finner ikke "<strong>{{ input.freg_housingType }}</strong>" hos oss.
        template(#selection="{ attrs, item, parent, selected }")
          v-chip(
            v-bind="attrs"
            :input-value="selected"
            color="secondary"
            small
          )
            span.pr-2 {{ item.text }}
            v-icon(
              @click="parent.selectItem(item)"
              small
            ) mdi-close

  //- Moving date
  .mb-4
    .text-subtitle-1.font-weight-normal.primary--text.d-flex(
      @click="locked.freg_movingDate ? null : expanded.freg_movingDate = !expanded.freg_movingDate"
    )
      v-icon(color="primary" small left) mdi-truck
      | Flyttedato
      v-spacer
      v-btn(icon small)
        v-icon(color="secondary" small)
          | {{ expanded.freg_movingDate ? 'mdi-minus' : 'mdi-plus' }}
    v-divider.mt-2(:class="{ 'pb-4': expanded.freg_movingDate }")

    template(v-if="expanded.freg_movingDate")
      v-row
        v-col(cols="12" md="6")
          v-menu(
            v-model="menu.freg_movingDate_min"
            v-bind="menuProps()"
            min-width="auto"
          )
            template(#activator="{ on, attrs }")
              v-text-field(
                v-bind="attrs"
                v-on="on"
                :disabled="locked.freg_movingDate"
                :value="freg_movingDate[0]"
                label="Flyttet etter"
                append-icon="mdi-calendar"
                rounded filled readonly
                hide-details
              )
            v-date-picker(
              v-model="freg_movingDate[0]"
              color="black"
              show-current
            )
        v-col(cols="12" md="6")
          v-menu(
            v-model="menu.freg_movingDate_max"
            v-bind="menuProps()"
            min-width="auto"
          )
            template(#activator="{ on, attrs }")
              v-text-field(
                v-bind="attrs"
                v-on="on"
                :disabled="locked.freg_movingDate"
                :value="freg_movingDate[1]"
                label="Flyttet før"
                append-icon="mdi-calendar"
                rounded filled readonly
                hide-details
              )
            v-date-picker(
              v-model="freg_movingDate[1]"
              color="black"
              show-current
            )

  //- Citizenship
  .mb-4
    .text-subtitle-1.font-weight-normal.primary--text.d-flex(
      @click="locked.freg_citizenship ? null : expanded.freg_citizenship = !expanded.freg_citizenship"
    )
      v-icon(color="primary" small left) mdi-passport
      | Statsborgerskap
      v-spacer
      v-btn(icon small)
        v-icon(color="secondary" small)
          | {{ expanded.freg_citizenship ? 'mdi-minus' : 'mdi-plus' }}
    v-divider.mt-2(:class="{ 'pb-4': expanded.freg_citizenship }")

    template(v-if="expanded.freg_citizenship")
      v-combobox.filter-chip(
        v-model="freg_citizenship"
        :disabled="locked.freg_citizenship || loading_search.freg_citizenship"
        :loading="loading_search.freg_citizenship"
        :search-input.sync="input_search.freg_citizenship"
        :items="items_search.freg_citizenship"
        :menu-props="menuProps()"
        multiple chips
        rounded filled dense
        hide-details
      )
        template(#no-data)
          v-list-item(v-if="input_search.freg_citizenship")
            v-list-item-content
              v-list-item-title
                | Vi finner ikke "<strong>{{ input_search.freg_citizenship }}</strong>" hos oss.
                | Trykk <kbd>enter</kbd> for å legge til uansett.
        template(#selection="{ attrs, item, parent, selected }")
          v-chip(
            v-bind="attrs"
            :input-value="selected"
            color="secondary"
            small
          )
            span.pr-2 {{ item }}
            v-icon(
              @click="parent.selectItem(item)"
              small
            ) mdi-close

  //- Birth country
  .mb-4
    .text-subtitle-1.font-weight-normal.primary--text.d-flex(
      @click="locked.freg_birthCountry ? null : expanded.freg_birthCountry = !expanded.freg_birthCountry"
    )
      v-icon(color="primary" small left) mdi-baby
      | Fødeland
      v-spacer
      v-btn(icon small)
        v-icon(color="secondary" small)
          | {{ expanded.freg_birthCountry ? 'mdi-minus' : 'mdi-plus' }}
    v-divider.mt-2(:class="{ 'pb-4': expanded.freg_birthCountry }")

    template(v-if="expanded.freg_birthCountry")
      v-combobox.filter-chip(
        v-model="freg_birthCountry"
        :disabled="locked.freg_birthCountry || loading_search.freg_birthCountry"
        :loading="loading_search.freg_birthCountry"
        :search-input.sync="input_search.freg_birthCountry"
        :items="items_search.freg_birthCountry"
        :menu-props="menuProps()"
        multiple chips
        rounded filled dense
        hide-details
      )
        template(#no-data)
          v-list-item(v-if="input_search.freg_birthCountry")
            v-list-item-content
              v-list-item-title
                | Vi finner ikke "<strong>{{ input_search.freg_birthCountry }}</strong>" hos oss.
                | Trykk <kbd>enter</kbd> for å legge til uansett.
        template(#selection="{ attrs, item, parent, selected }")
          v-chip(
            v-bind="attrs"
            :input-value="selected"
            color="secondary"
            small
          )
            span.pr-2 {{ item }}
            v-icon(
              @click="parent.selectItem(item)"
              small
            ) mdi-close

  //- Civil status
  .mb-4
    .text-subtitle-1.font-weight-normal.primary--text.d-flex(
      @click="locked.freg_civilStatus ? null : expanded.freg_civilStatus = !expanded.freg_civilStatus"
    )
      v-icon(color="primary" small left) mdi-ring
      | Sivilstatus
      v-spacer
      v-btn(icon small)
        v-icon(color="secondary" small)
          | {{ expanded.freg_civilStatus ? 'mdi-minus' : 'mdi-plus' }}
    v-divider.mt-2(:class="{ 'pb-4': expanded.freg_civilStatus }")

    template(v-if="expanded.freg_civilStatus")
      v-autocomplete.filter-chip(
        v-model="freg_civilStatus"
        :disabled="locked.freg_civilStatus"
        :search-input.sync="input.freg_civilStatus"
        :items="items.freg_civilStatus"
        :menu-props="menuProps()"
        multiple chips
        rounded filled dense
        hide-details
      )
        template(#no-data)
          v-list-item(v-if="input.freg_civilStatus")
            v-list-item-content
              v-list-item-title
                | Vi finner ikke "<strong>{{ input.freg_civilStatus }}</strong>" hos oss.
        template(#selection="{ attrs, item, parent, selected }")
          v-chip(
            v-bind="attrs"
            :input-value="selected"
            color="secondary"
            small
          )
            span.pr-2 {{ item.text }}
            v-icon(
              @click="parent.selectItem(item)"
              small
            ) mdi-close

  //- SVV car brand name
  .mb-4
    .text-subtitle-1.font-weight-normal.primary--text.d-flex(
      @click="locked.svv_car_brandName ? null : expanded.svv_car_brandName = !expanded.svv_car_brandName"
    )
      v-icon(color="primary" small left) mdi-car
      | Bilmerke
      v-spacer
      v-btn(icon small)
        v-icon(color="secondary" small)
          | {{ expanded.svv_car_brandName ? 'mdi-minus' : 'mdi-plus' }}
    v-divider.mt-2(:class="{ 'pb-4': expanded.svv_car_brandName }")

    template(v-if="expanded.svv_car_brandName")
      v-combobox.filter-chip(
        v-model="svv_car_brandName"
        :disabled="locked.svv_car_brandName || loading_search.svv_car_brandName"
        :loading="loading_search.svv_car_brandName"
        :search-input.sync="input_search.svv_car_brandName"
        :items="items_search.svv_car_brandName"
        :menu-props="menuProps()"
        multiple chips
        rounded filled dense
        hide-details
      )
        template(#no-data)
          v-list-item(v-if="input_search.svv_car_brandName")
            v-list-item-content
              v-list-item-title
                | Vi finner ikke "<strong>{{ input_search.svv_car_brandName }}</strong>" hos oss.
                | Trykk <kbd>enter</kbd> for å legge til uansett.
        template(#selection="{ attrs, item, parent, selected }")
          v-chip(
            v-bind="attrs"
            :input-value="selected"
            color="secondary"
            small
          )
            span.pr-2 {{ item }}
            v-icon(
              @click="parent.selectItem(item)"
              small
            ) mdi-close

  //- SVV car fuel code
  .mb-4
    .text-subtitle-1.font-weight-normal.primary--text.d-flex(
      @click="locked.svv_car_fuelCode ? null : expanded.svv_car_fuelCode = !expanded.svv_car_fuelCode"
    )
      v-icon(color="primary" small left) mdi-gas-station
      | Drivstoff
      v-spacer
      v-btn(icon small)
        v-icon(color="secondary" small)
          | {{ expanded.svv_car_fuelCode ? 'mdi-minus' : 'mdi-plus' }}
    v-divider.mt-2(:class="{ 'pb-4': expanded.svv_car_fuelCode }")

    template(v-if="expanded.svv_car_fuelCode")
      v-autocomplete.filter-chip(
        v-model="svv_car_fuelCode"
        :disabled="locked.svv_car_fuelCode"
        :search-input.sync="input.svv_car_fuelCode"
        :items="items.svv_car_fuelCode"
        :menu-props="menuProps()"
        multiple chips
        rounded filled dense
        hide-details
      )
        template(#no-data)
          v-list-item(v-if="input.svv_car_fuelCode")
            v-list-item-content
              v-list-item-title
                | Vi finner ikke "<strong>{{ input.svv_car_fuelCode }}</strong>" hos oss.
        template(#selection="{ attrs, item, parent, selected }")
          v-chip(
            v-bind="attrs"
            :input-value="selected"
            color="secondary"
            small
          )
            span.pr-2 {{ item.text }}
            v-icon(
              @click="parent.selectItem(item)"
              small
            ) mdi-close

  //- Show reserved
  .mb-4
    .text-subtitle-1.font-weight-normal.primary--text.d-flex(
      @click="locked.rreg_showReserved ? null : expanded.rreg_showReserved = !expanded.rreg_showReserved"
    )
      v-icon(color="primary" small left) mdi-phone-off
      | Inkluder reserverte numre
      v-spacer
      v-btn(icon small)
        v-icon(color="secondary" small)
          | {{ expanded.rreg_showReserved ? 'mdi-minus' : 'mdi-plus' }}
    v-divider.mt-2(:class="{ 'pb-4': expanded.rreg_showReserved }")

    template(v-if="expanded.rreg_showReserved")
      v-switch.mt-0.ml-1(
        v-model="rreg_showReserved"
        :disabled="locked.rreg_showReserved"
        label="Ja"
        color="secondary"
        inset hide-details
      )
</template>

<script>
import { get, debounce } from 'lodash'
import { mapActions } from 'vuex'
import { menuProps, rules } from '@/mixins'
import dayjs from 'dayjs'

export default {
  name: 'Filters',
  mixins: [menuProps, rules],
  props: ['value', 'lockedFilters'],
  data: () => ({
    form: false,

    expanded: {
      freg_age: false,
      freg_gender: false,
      nrop_postalCode: false,
      nrop_city: false,
      freg_housingType: false,
      freg_movingDate: false,
      freg_citizenship: false,
      freg_birthCountry: false,
      freg_civilStatus: false,
      svv_car_brandName: false,
      svv_car_fuelCode: false,
      rreg_showReserved: false
    },
    locked: {
      freg_age: false,
      freg_gender: false,
      nrop_postalCode: false,
      nrop_city: false,
      freg_housingType: false,
      freg_movingDate: false,
      freg_citizenship: false,
      freg_birthCountry: false,
      freg_civilStatus: false,
      svv_car_brandName: false,
      svv_car_fuelCode: false,
      rreg_showReserved: false
    },
    loading_search: {
      nrop_city: false,
      freg_citizenship: false,
      freg_birthCountry: false,
      svv_car_brandName: false
    },
    input_search: {
      nrop_city: null,
      freg_citizenship: null,
      freg_birthCountry: null,
      svv_car_brandName: null
    },
    items_search: {
      nrop_city: [],
      freg_citizenship: [],
      freg_birthCountry: [],
      svv_car_brandName: []
    },
    input: {
      freg_housingType: null,
      freg_civilStatus: null,
      svv_car_fuelCode: null
    },
    items: {
      freg_housingType: [
        { text: 'Bolig', value: 'bolig' },
        { text: 'Annet enn bolig', value: 'annetEnnBolig' },
        { text: 'Fritidsbolig', value: 'fritidsbolig' },
        { text: 'Ikke godkjent bolig', value: 'ikkeGodkjentBolig' },
        { text: 'Unummerert bruksenhet', value: 'unummerertBruksenhet' }
      ],
      freg_civilStatus: [
        { text: 'Uoppgitt', value: 'uoppgitt' },
        { text: 'Ugift', value: 'ugift' },
        { text: 'Gift', value: 'gift' },
        { text: 'Enke eller enkemann', value: 'enkeEllerEnkemann' },
        { text: 'Skilt', value: 'skilt' },
        { text: 'Registrert partner', value: 'registrertPartner' },
        { text: 'Separert partner', value: 'separertPartner' },
        { text: 'Skilt partner', value: 'skiltPartner' },
        { text: 'Gjenlevende partner', value: 'gjenlevendePartner' }
      ],
      svv_car_fuelCode: [
        { text: 'Bensin', value: 1 },
        { text: 'Diesel', value: 2 },
        { text: 'Parafin', value: 3 },
        { text: 'Gass', value: 4 },
        { text: 'Elektrisk', value: 5 },
        { text: 'Hydrogen', value: 6 },
        { text: 'Biodiesel', value: 10 },
        { text: 'Biobensin', value: 11 },
        { text: 'LPG-gass', value: 12 },
        { text: 'CNG-gass', value: 13 },
        { text: 'Metanol', value: 14 },
        { text: 'Etanol', value: 15 },
        { text: 'Komprimert luft', value: 20 },
        { text: 'Annet drivstoff', value: 9 }
      ]
    },
    menu: {
      freg_movingDate_min: false,
      freg_movingDate_max: false
    },

    freg_age: null,
    freg_age_min: 17,
    freg_age_max: 121,
    freg_gender: null,
    nrop_postalCode: null,
    nrop_postalCode_min: -1,
    nrop_postalCode_max: 10000,
    nrop_city: null,
    freg_housingType: null,
    freg_movingDate: null,
    freg_citizenship: null,
    freg_birthCountry: null,
    freg_civilStatus: null,
    svv_car_brandName: null,
    svv_car_fuelCode: null,
    rreg_showReserved: null
  }),
  computed: {
    freg_age_at_min() {
      return (
        this.freg_age[0] < this.freg_age_min + 1 ||
        this.freg_age[0] > this.freg_age_max - 1
      )
    },
    freg_age_at_max() {
      return (
        this.freg_age[1] > this.freg_age_max - 1 ||
        this.freg_age[1] < this.freg_age_min + 1
      )
    },
    nrop_postalCode_at_min() {
      return (
        this.nrop_postalCode[0] < this.nrop_postalCode_min + 1 ||
        this.nrop_postalCode[0] > this.nrop_postalCode_max - 1
      )
    },
    nrop_postalCode_at_max() {
      return (
        this.nrop_postalCode[1] > this.nrop_postalCode_max - 1 ||
        this.nrop_postalCode[1] < this.nrop_postalCode_min + 1
      )
    },
    filters() {
      return {
        freg_age: this.freg_age,
        freg_gender: this.freg_gender,
        nrop_postalCode: this.nrop_postalCode,
        nrop_city: this.nrop_city,
        freg_housingType: this.freg_housingType,
        freg_movingDate: this.freg_movingDate,
        freg_citizenship: this.freg_citizenship,
        freg_birthCountry: this.freg_birthCountry,
        freg_civilStatus: this.freg_civilStatus,
        svv_car_brandName: this.svv_car_brandName,
        svv_car_fuelCode: this.svv_car_fuelCode,
        rreg_showReserved: this.rreg_showReserved
      }
    }
  },
  watch: {
    'expanded.freg_age'(v) {
      this.freg_age = v ? this.freg_age || [17, 121] : null
    },
    'expanded.freg_gender'(v) {
      this.freg_gender = v ? this.freg_gender || ['M', 'F'] : null
    },
    'expanded.nrop_postalCode'(v) {
      this.nrop_postalCode = v ? this.nrop_postalCode || [-1, 10000] : null
    },
    'expanded.nrop_city'(v) {
      this.nrop_city = v ? this.nrop_city || [] : null
    },
    'expanded.freg_housingType'(v) {
      this.freg_housingType = v ? this.freg_housingType || [] : null
    },
    'expanded.freg_movingDate'(v) {
      this.freg_movingDate = v
        ? this.freg_movingDate || [
            dayjs().format('YYYY-MM-DD'),
            dayjs().format('YYYY-MM-DD')
          ]
        : null
    },
    'expanded.freg_citizenship'(v) {
      this.freg_citizenship = v ? this.freg_citizenship || [] : null
    },
    'expanded.freg_birthCountry'(v) {
      this.freg_birthCountry = v ? this.freg_birthCountry || [] : null
    },
    'expanded.freg_civilStatus'(v) {
      this.freg_civilStatus = v ? this.freg_civilStatus || [] : null
    },
    'expanded.svv_car_brandName'(v) {
      this.svv_car_brandName = v ? this.svv_car_brandName || [] : null
    },
    'expanded.svv_car_fuelCode'(v) {
      this.svv_car_fuelCode = v ? this.svv_car_fuelCode || [] : null
    },
    'expanded.rreg_showReserved'(v) {
      this.rreg_showReserved = v ? this.rreg_showReserved || false : null
    },
    'input_search.nrop_city': debounce(async function (value) {
      if (!value) return

      this.loading_search.nrop_city = true
      try {
        this.items_search.nrop_city = await this.distinct({
          type: 'nrop.city',
          value
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading_search.nrop_city = false
      }
    }, 250),
    'input_search.freg_citizenship': debounce(async function (value) {
      if (!value) return

      this.loading_search.freg_citizenship = true
      try {
        this.items_search.freg_citizenship = await this.distinct({
          type: 'freg.citizenship',
          value
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading_search.freg_citizenship = false
      }
    }, 250),
    'input_search.freg_birthCountry': debounce(async function (value) {
      if (!value) return

      this.loading_search.freg_birthCountry = true
      try {
        this.items_search.freg_birthCountry = await this.distinct({
          type: 'freg.birthCountry',
          value
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading_search.freg_birthCountry = false
      }
    }, 250),
    'input_search.svv_car_brandName': debounce(async function (value) {
      if (!value) return

      this.loading_search.svv_car_brandName = true
      try {
        this.items_search.svv_car_brandName = await this.distinct({
          type: 'svv.car.brandName',
          value
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading_search.svv_car_brandName = false
      }
    }, 250),
    value: {
      immediate: true,
      handler(v) {
        const freg_age = get(v, 'freg_age', null)
        const freg_gender = get(v, 'freg_gender', null)
        const nrop_postalCode = get(v, 'nrop_postalCode', null)
        const nrop_city = get(v, 'nrop_city', null)
        const freg_housingType = get(v, 'freg_housingType', null)
        const freg_movingDate = get(v, 'freg_movingDate', null)
        const freg_citizenship = get(v, 'freg_citizenship', null)
        const freg_birthCountry = get(v, 'freg_birthCountry', null)
        const freg_civilStatus = get(v, 'freg_civilStatus', null)
        const svv_car_brandName = get(v, 'svv_car_brandName', null)
        const svv_car_fuelCode = get(v, 'svv_car_fuelCode', null)
        const rreg_showReserved = get(v, 'rreg_showReserved', null)

        const initialize_filter = (obj) => {
          const filter_name = Object.keys(obj)[0]
          const filter_value = Object.values(obj)[0]

          if (filter_value !== null) {
            this[filter_name] = filter_value
            this.expanded[filter_name] = true
          }
        }

        initialize_filter({ freg_age })
        initialize_filter({ freg_gender })
        initialize_filter({ nrop_postalCode })
        initialize_filter({ nrop_city })
        initialize_filter({ freg_housingType })
        initialize_filter({ freg_movingDate })
        initialize_filter({ freg_citizenship })
        initialize_filter({ freg_birthCountry })
        initialize_filter({ freg_civilStatus })
        initialize_filter({ svv_car_brandName })
        initialize_filter({ svv_car_fuelCode })
        initialize_filter({ rreg_showReserved })
      }
    },
    lockedFilters: {
      immediate: true,
      handler(v) {
        if (!v) return

        for (const [filter_name, filter_value] of Object.entries(v)) {
          if (filter_value !== null) {
            this[filter_name] = filter_value
            this.locked[filter_name] = true
            this.expanded[filter_name] = true
          } else {
            this.locked[filter_name] = false
          }
        }
      }
    },
    filters: {
      immediate: true,
      handler(v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Search', ['distinct']),
    changePostalCode(index, value) {
      this.$set(
        this.nrop_postalCode,
        index,
        isNaN(value)
          ? index > 0
            ? this.nrop_postalCode_max
            : this.nrop_postalCode_min
          : value
      )
    }
  }
}
</script>

<style lang="stylus" scoped>
.v-form
  .text-subtitle-1
    cursor pointer

  >>> .filter-chip
    .v-input__control
      .v-input__slot
        padding-left 6px !important
</style>
